import { graphql, Link, PageProps } from "gatsby";
import React from "react";

export const query = graphql`
  query BlogPage {
    allMdx(sort: { order: DESC, fields: frontmatter___datePublished }) {
      nodes {
        id

        frontmatter {
          slug
          title
          datePublished(formatString: "MMMM D, YYYY")
        }
        excerpt
      }
    }
  }
`;

const BlogPage = ({ data }: PageProps<Queries.BlogPageQuery>) => (
  <div>
    <h1>blog page</h1>
    {data.allMdx.nodes.map((node) => (
      <article key={node.id}>
        <Link to={`/blog/${node.frontmatter?.slug}`}>
          <h2>{node.frontmatter?.title}</h2>
        </Link>
        <p> {node.frontmatter?.datePublished}</p>
        <p>{node.excerpt}</p>
      </article>
    ))}
  </div>
);

export default BlogPage;
